import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import ErrorReportImage from "../../images/inHouseImages/errorReportImage.jpg";
import ProfileCard from "../../components/ProfileCard.js";
import andreasA from "../../images/profiles/profileAndreasA.jpg";


function Report() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Felanmälan"
            keywords={[`felanmälan`, `felavhjälpning`,]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img className="w-full" src={ErrorReportImage} alt="phonesupport" title="phonesupport"></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Felanmälan</div>
                <p className="text-gray-700 text-base">
                  Vi tillhandahåller hantering av felanmälan åt våra kunder.
                  Detta innebär att de kan skicka in felanmälningar som vi
                  koordinerar ut till rätt entreprenör som ser till att
                  felavhjälpning utförs inom utsatt tid.
                  <br></br>
                  <br></br>
                  Vi blir den sammankopplande länken som ser till att våra
                  kunder får de bästa förutsättningarna för att deras
                  verksamheter ska rulla på så smidigt som möjligt.
                </p>
              </div>
              <ProfileCard
                profile={andreasA}
                name="Andreas Andersson"
                email="aa@psksyd.com"
              />
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Report;
